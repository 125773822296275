import React from "react"
import Img from "gatsby-image"
import cuid from "cuid"
import PhotoGallery from "react-photo-gallery"

import styled from "styled-components"
import { Row, Col } from "react-styled-flexboxgrid"
import { Button } from "../../ui/atoms/CTA"

import { LeadText, SubTitle, Text } from "../../ui/atoms/Typography"
import { StyledGrid, Space } from "../../templates/Layout"
import { Section } from "../../ui/molecules/Section"
import Flower from "../../ui/atoms/Flower"

import ImgFlower from "../../../images/chopsticks-flower.svg"

import ImgSet from "../../../images/gallery/img-set.jpg"
import ImgSetLight from "../../../images/gallery/img-set-light.jpg"
import ImgOurVision from "../../../images/gallery/img-our-vision.jpg"
import ImgFork from "../../../images/gallery/img-fork.jpg"
import ImgNewsVideo from "../../../images/gallery/img-news-video.jpg"

import ImgCompostable from "../../../images/gallery/img-compostable.jpg"

const photos = [
  {
    id: cuid(),
    src: ImgSet,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 10,
    height: 7,
    alt: "touchless flatware set",
  },
  {
    id: cuid(),
    src: ImgSetLight,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 5,
    height: 3,
    alt: "touchless flatware set different view",
  },
  {
    id: cuid(),
    src: ImgOurVision,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "touchless spon in hand",
  },
  {
    id: cuid(),
    src: ImgFork,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "touchless fork",
  },
  {
    id: cuid(),
    src: ImgNewsVideo,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "touchless spoon",
  },
  // {
  //   id: cuid(),
  //   src: ImgChopsticks,
  //   sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
  //   width: 4,
  //   height: 4,
  //   alt: "Chopsticks",
  //   caption: "Chopsticks",
  // },
  {
    id: cuid(),
    src: ImgCompostable,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "Plastic",
    caption: "Plastic",
  },
]

const UnstyledList = styled.ul`
  margin: 0;
  li {
    list-style: none;
    color: white;
  }
  @media screen and (max-width: 39.9375em) {
    margin-bottom: 4rem;
  }
`

const Press = ({ data }) => {
  return (
    <>
      <Section type="tertiary" centered>
        <StyledGrid>
          <Row center="md">
            <Col xs={12} md={9}>
              <Flower
                src={ImgFlower}
                alt="logo flower design"
                height={150}
                pushUp
              />
              <SubTitle type="light" align="center">
                <strong>Applications & Industries</strong>
              </SubTitle>
              <Img
                fluid={data.ImgFirstResponder.childImageSharp.fluid}
                alt="nurse in the field"
              />
              <Space mb={32} />
              <LeadText type="alternate" align="center">
                Touchless Chopsticks can benefit so many
              </LeadText>
              <Row>
                <Col xs={12} md={4}>
                  <UnstyledList>
                    <li>Family Meals</li>
                    <li>Restaurants</li>
                    <li>Food Trucks</li>
                    <li>Hospitals</li>
                    <li>Cooking Classes</li>
                  </UnstyledList>
                </Col>
                <Col xs={12} md={4}>
                  <UnstyledList>
                    <li>Home Entertaining</li>
                    <li>Caterers</li>
                    <li>Sushi Bars</li>
                    <li>Nursing Homes</li>
                    <li>Event Planners</li>
                  </UnstyledList>
                </Col>
                <Col xs={12} md={4}>
                  <UnstyledList>
                    <li>Take-out Delivery</li>
                    <li>Personal Chefs</li>
                    <li>Restaurant Suppliers</li>
                    <li>Retirement Homes</li>
                    <li>Chef Demonstrations</li>
                  </UnstyledList>
                </Col>
              </Row>
            </Col>
          </Row>
        </StyledGrid>
      </Section>

      <Section type="secondary" centered>
        <StyledGrid>
          <Row center="md">
            <Col xs={12} md={9}>
              <SubTitle type="alternate" align="center">
                <strong>The Ultimate Benefactors</strong>
              </SubTitle>

              <Img
                fluid={data.ImgBabyTurtle.childImageSharp.fluid}
                alt="baby sea turtle"
              />
              <Space mb={32} />
              <LeadText align="center" type="dark">
                Go Touchless! Be Touchless! Live Touchless!
              </LeadText>
            </Col>
          </Row>
        </StyledGrid>
      </Section>

      <Section type="tertiary" centered>
        <StyledGrid>
          <Row center="md">
            <Col xs={12} md={9}>
              <Flower
                src={ImgFlower}
                alt="logo flower design"
                height={150}
                pushUp
              />
              <SubTitle align="center">History of Chopsticks</SubTitle>
              <LeadText type="alternate" align="center">
                A salute to human innovation from the past
              </LeadText>
              <Img
                fluid={data.ImgGoldChopsticks.childImageSharp.fluid}
                alt="ancient golden chopsticks"
              />
              <Space mb={32} />
              <Text align="center">
                Chopsticks were invented in ancient China, likely before the
                Shang dynasty (1766 - 1122 BC). Some believe they can be traced
                all the way back before the Xia dynasty 9,000 years ago. The
                earliest evidence of their existence are six chopsticks made of
                bronze, 10 inches long and almost a half-inch wide. These rare
                artifacts were excavated from the Ruins of Yin near Anyang
                (Henan) and date roughly to 1200 BC. It is believed they were
                used for cooking. The earliest known written reference of
                chopsticks comes from the Han Feizi, an ancient text written by
                political philosopher Han Fei (c. 280–233 BC).
              </Text>
            </Col>
          </Row>
        </StyledGrid>
      </Section>
      <Section type="secondary" centered>
        <Row center="md">
          <Col xs={12} md={8}>
            <LeadText type="dark" align="center">
              See the entire line of Touchless Flatware™
            </LeadText>
            <PhotoGallery
              photos={photos}
              onClick={() =>
                window.open("https://www.touchlessflatware.com/", "_blank")
              }
            />
            <Space mb={32} />
            <Button
              onClick={() =>
                window.open("https://www.touchlessflatware.com/", "_blank")
              }
              secondary
            >
              View Touchless Flatware
            </Button>
          </Col>
        </Row>
      </Section>
    </>
  )
}

export default Press
