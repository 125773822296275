import React from "react"
import PropTypes from "prop-types"
import StyledSvgBase from "./StyledSvgBase"

const IconHomeMenu = ({ fillColor, width }) => {
  return (
    <StyledSvgBase viewBox="0 0 32 31.15" width={width}>
      <path
        className="cls-1"
        d="M20.68,15.32A4.68,4.68,0,1,1,16,10.64,4.68,4.68,0,0,1,20.68,15.32Z"
      />
      <path
        className="cls-2"
        d="M18.43,15.32A2.43,2.43,0,1,1,16,12.89,2.43,2.43,0,0,1,18.43,15.32Z"
      />
      <path
        className="cls-3"
        d="M26.35,27.64H6v-.75c0-1.51-1-7.61-1.75-11.47L3,16.39,0,12.81,16.25,0,32,12.57l-3.18,3.55-.74-.57c-.7,3.83-1.74,9.87-1.74,11.34ZM7.5,26.15H24.89c.27-3.16,1.88-11.69,1.95-12.09l.23-1.21,1.57,1.24,1.19-1.34L16.25,1.91,2.14,13l1.1,1.31,2.07-1.59L5.53,14C5.61,14.34,7.22,23,7.5,26.15Z"
      />
      <rect className="cls-4" x="11.85" y="29.2" width="8.3" height="1.94" />
    </StyledSvgBase>
  )
}

IconHomeMenu.defaultProps = {
  fillColor: "#15133f",
  width: 30,
}

IconHomeMenu.propTypes = {
  fillColor: PropTypes.string,
  width: PropTypes.number,
}

export default IconHomeMenu
