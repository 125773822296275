import React from "react"
import Scrollchor from "react-scrollchor"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Row, Col } from "react-styled-flexboxgrid"
import { IconMobileMenu, IconMobileMenuClose } from "../../atoms/Icons"
import MenuLinkStyle from "./style"
import LogoMobile from "../../../../images/logo-typestack.svg"
import { calculateRem } from "../../../../utils/javascript-styles"

const navArray = PropTypes.shape({
  id: PropTypes.string,
  route: PropTypes.string,
  customClass: PropTypes.string,
  title: PropTypes.string,
})

const NavContainer = styled.nav`
  position: fixed;
  width: 100%;
  z-index: 99999;
  box-shadow: 1px 1px 11px rgba(0, 0, 0, 0.1);
`

const MobileNavContainer = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: ${calculateRem(10)};
  position: relative;
  z-index: 2;
`

const MobileNavButton = styled.button`
  background: transparent;
  border: 0;
  margin: 0;
  width: ${calculateRem(50)};
  svg {
    display: block;
    margin: 0;
  }
`

const StyledLogoMobile = styled.img`
  margin: 0 ${calculateRem(10)};
  padding: 0;
  height: ${calculateRem(40)};
`

const MobileMenuLink = styled(Scrollchor)`
  ${MenuLinkStyle};
  border: 0;
  background-color: transparent;
  font-family: "Roboto Slab";
  font-size: ${calculateRem(30)};
`

const MobileMenuLinkShop = styled.button`
  ${MenuLinkStyle};
  font-family: "Roboto Slab";
  font-size: ${calculateRem(30)};
  background-color: transparent;
  border: 0;
`

const MobileDDMenu = styled.div`
  position: relative;
  background-color: #fff;
  border-bottom: 1px solid ${props => props.theme.gallery};
  height: 100vh;
  margin: 0 auto;
  opacity: ${props => (props.isActive ? "1" : 0)};
  visibility: ${props => (props.isActive ? "visible" : "hidden")};
`

const MobileNav = ({
  activeLink,
  mobileMenuControlFunc,
  controlAfterAnimate,
  mobileMenuOpen,
  navMenu,
}) => {
  return (
    <NavContainer>
      <Row>
        <Col xs={12}>
          <MobileNavContainer>
            <StyledLogoMobile
              src={LogoMobile}
              alt="Ornelas Technologies logo"
            />
            <MobileNavButton onClick={mobileMenuControlFunc}>
              {!mobileMenuOpen ? (
                <IconMobileMenu fillColor="#000" />
              ) : (
                <IconMobileMenuClose fillColor="#000" />
              )}
            </MobileNavButton>
          </MobileNavContainer>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {mobileMenuOpen && (
            <MobileDDMenu isActive={mobileMenuOpen}>
              {navMenu.map(item => (
                <MobileMenuLink
                  key={item.anchor}
                  to={item.anchor}
                  className={activeLink === item.anchor ? "active" : ""}
                  beforeAnimate={() => controlAfterAnimate(item.anchor)}
                >
                  {item.title}
                </MobileMenuLink>
              ))}
              <MobileMenuLinkShop
                onClick={() =>
                  window.open(
                    "https://touchless-tableware.myshopify.com/",
                    "_blank"
                  )
                }
              >
                Shop
              </MobileMenuLinkShop>
            </MobileDDMenu>
          )}
        </Col>
      </Row>
    </NavContainer>
  )
}

MobileNav.propTypes = {
  navMenu: PropTypes.arrayOf(navArray).isRequired,
  mobileMenuOpen: PropTypes.bool.isRequired,
  mobileMenuControlFunc: PropTypes.func.isRequired,
  menuButtonControlFunc: PropTypes.func.isRequired,
  mobileNavControlFunc: PropTypes.func,
}

export default MobileNav
