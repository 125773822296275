import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import Flex from "../../ui/atoms/Flex"
import { Box } from "reflexbox"
import { Row, Col } from "react-styled-flexboxgrid"
import { LeadText, Title, SubTitle, Text } from "../../ui/atoms/Typography"
import { Layout, Space, StyledGrid } from "../../templates/Layout"
import { Section } from "../../ui/molecules/Section"
import SEO from "../SEO"
import { Button, StyledLink } from "../../ui/atoms/CTA"
import Flower from "../../ui/atoms/Flower"
import IconUniqueDesign from "../../../images/icon-unique-design.svg"
import IconErgonomic from "../../../images/icon-ergonomic.svg"
import IconEco from "../../../images/icon-eco.svg"

import ImgFlower from "../../../images/chopsticks-flower.svg"
import SvgMadeUsa from "../../../images/made-in-usa.svg"
import ImgASTM from "../../../images/logo-astm.svg"

import { calculateRem } from "../../../utils/javascript-styles"

const StyledIcons = styled.img`
  height: ${calculateRem(100)};
`

const SubTitleHero = styled.h3`
  color: ${props => props.theme.canonPink};
  font-family: "Poppins", san-serif;
  font-size: ${calculateRem(32)};
  display: block;
  text-align: center;
  font-weight: 100;
  @media screen and (max-width: 39.9375em) {
    font-size: ${calculateRem(22)};
    text-align: center;
  }
`

const ImgMadeUsa = styled.img`
  display: block;
  width: ${calculateRem(84)};
  margin: 0 auto;
`

const StyledASMTLogo = styled.img`
  width: ${calculateRem(50)};
`
const Home = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <section id="home">
        <Section id="home" centered>
          <StyledGrid>
            <Row center="md">
              <Col xs={12} md={10}>
                <Space mb={32} />
                <Flower src={ImgFlower} alt="logo flower design" height={150} />
                <Title secondary align="center">
                  Touchless Chopsticks
                </Title>
                <Space mb={64} />
                <Img
                  fluid={data.imgchopsticks.childImageSharp.fluid}
                  alt="chopsticks"
                />
                <Space mb={64} />
                <SubTitleHero align="center">
                  <strong>The hygienic, high-tech utensil of the future</strong>
                </SubTitleHero>
                <LeadText type="dark" align="center">
                  by Michael Joyner MD, creator of Touchless Flatware™
                </LeadText>
                <ImgMadeUsa src={SvgMadeUsa} alt="made in usa logo" />
              </Col>
            </Row>
          </StyledGrid>
        </Section>
        <Section id="home" type="dark" centered>
          <StyledGrid>
            <Row center="md">
              <Col xs={12} md={10}>
                <SubTitle align="center">
                  Prevent the spread of germs and viruses in style
                </SubTitle>
                <Text align="center">
                  Touchless Chopsticks are a novel solution to keep the utensil
                  tips from touching the table. They’re designed by a renowned
                  hand surgeon to be more hygienic, easier to handle and
                  harmless to the environment. Let’s face it: recurrent viruses
                  like COVID-19, SARS and MERS have plagued society for a
                  decade. Placing utensils on a contaminated surface can make
                  you terribly sick or cost you your life. Touchless Chopsticks
                  alleviate those fears and your risk—one stylish dining
                  experience at a time.
                </Text>

                <Space mb={32} />

                <Button
                  onClick={() =>
                    window.open(
                      "https://touchless-tableware.myshopify.com/",
                      "_blank"
                    )
                  }
                >
                  Go Touchless!
                </Button>
              </Col>
            </Row>
          </StyledGrid>
        </Section>
        <Section type="gradient" centered>
          <StyledGrid>
            <Row>
              <Col xs={12}>
                <SubTitle align="center">Key product features</SubTitle>
                <Row>
                  <Col xs={12} md={4}>
                    <StyledIcons
                      src={IconUniqueDesign}
                      alt="icon unique design"
                    />
                    <Text align="center">
                      Unique design keeps tips off the table
                    </Text>
                  </Col>
                  <Col xs={12} md={4}>
                    <StyledIcons src={IconErgonomic} alt="icon ergonomic" />
                    <Text align="center">
                      Tapered, ergonomic design for an easier grip
                    </Text>
                  </Col>
                  <Col xs={12} md={4}>
                    <StyledIcons src={IconEco} alt="icon eco" />
                    <Text align="center">Reusable</Text>
                  </Col>
                </Row>
                <Space mb={40} />

                <Row>
                  <Col xs={12}>
                    <Button
                      onClick={() =>
                        window.open(
                          "https://touchless-tableware.myshopify.com/",
                          "_blank"
                        )
                      }
                    >
                      Shop Now
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Space mt={30} />
                    <Flex justifyContent="center">
                      <Text align="center">
                        <strong>
                          Great for sushi and other Asian cuisines
                        </strong>
                      </Text>
                    </Flex>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Box display="flex" justifyContent="center">
                      <Box display="flex">
                        <Box mr={2}>
                          <StyledASMTLogo src={ImgASTM} alt="astm logo" />
                        </Box>
                        <Box pt={1}>
                          <Text>
                            ASTM standards have been adopted. For more
                            information, visit{" "}
                            <StyledLink
                              display="inline-block"
                              href="http://astm.org/"
                            >
                              ASTM.org
                            </StyledLink>
                            .
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  </Col>
                </Row>
              </Col>
            </Row>
          </StyledGrid>
        </Section>
      </section>
    </Layout>
  )
}

export default Home
