import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import { calculateRem } from "../../../../utils/javascript-styles"

const RadioWrapper = styled.div`
  position: relative;
  margin-top: 2rem;
  display: inline-block;
  input[type='radio'] {
    opacity: 0;
    display: none;
    visibility: hidden;
    position: absolute;
    left: -9999px;
  }
  label {
    color: ${props => props.theme.white};
    position: relative;
    display: inline-block;

    /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
    padding-left: ${calculateRem(10)};
  }
  label::before {
    content: '';
    display: inline-block;
    background-color: ${props => props.theme.haitiLight};
    height: ${calculateRem(23)};
    width: ${calculateRem(23)};

    border: 1px solid ${props => props.theme.violetta};
    border-radius: 50%;
  }
  label::after {
    background: ${props => props.theme.shakespeare};
    content: '';
    display: inline-block;
    height: ${calculateRem(13)};
    width: ${calculateRem(13)};
    border-radius: 50%;
    border: 1px solid ${props => props.theme.shakespeare};
    transition: all 200ms;
  }
  label::before,
  label::after {
    position: absolute;
    z-index: 1;
  }
  label::before {
    top: ${calculateRem(0)};
    left: ${calculateRem(-19)};
  }
  label::after {
    left: ${calculateRem(-14)};
    top: ${calculateRem(5)};
  }

  input[type='radio'] + label::after {
    content: none;
  }
  input[type='radio']:checked + label::after {
    content: '';
  }
  /* Small only */
  @media screen and (max-width: 39.9375em) {
    display: block;
    width: 100%;
    text-align: left;
    margin-left: 1rem;
}


  }
`

const Radio = ({ id, labelText, radioName, checkedOption, onChangeFunc }) => {
  return (
    <RadioWrapper>
      <input
        type="radio"
        id={id}
        value={id}
        name={radioName}
        checked={checkedOption}
        onChange={onChangeFunc}
      />
      <label htmlFor={id}>{labelText}</label>
    </RadioWrapper>
  )
}

Radio.propTypes = {
  id: PropTypes.string,
  labelText: PropTypes.string,
  radioName: PropTypes.string,
}

export default Radio
