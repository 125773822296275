import styled from "styled-components"
import { calculateRem } from "../../../../utils/javascript-styles"

const StyledSvgBase = styled.svg`
  overflow: visible;
  height: ${props => calculateRem(props.svgHeight)};
  fill: ${props => props.fillColor};
  transition: all 200ms;
  &:hover {
    fill: ${props => props.fillColorHover};
  }
`
export default StyledSvgBase
