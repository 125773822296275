import styled from "styled-components"

const TextArea = styled.textarea`
  width: 100%;
  border: 1px solid ${props => props.theme.thunder};
  background-color: ${props => props.theme.white};
  margin-bottom: 2rem;
  color: ${props => props.theme.merlot};
  padding: 1rem;
`

export default TextArea
