import React from "react"
import cuid from "cuid"
import PropTypes from "prop-types"
import StyledSvgBase from "./StyledSvgBase"

const Instagram = ({ title, height, fillColor, fillColorHover }) => {
  return (
    <StyledSvgBase
      svgHeight={height}
      data-name={title}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      fillColor={fillColor}
      fillColorHover={fillColorHover}
      role="img"
    >
      <title>{title}</title>
      <path
        d="M25 0a25 25 0 1 0 25 25A25 25 0 0 0 25 0zm0 3a22 22 0 1 0 22 22A22 22 0 0 0 25 3zm11 31.51v-19a2.42 2.42 0 0 0-2-2H16.17l-.31.06A2.44 2.44 0 0 0 14 15.65v18.58l.06.35a2.43 2.43 0 0 0 2 1.9H33.8l.34-.06A2.45 2.45 0 0 0 36 34.51zM16.38 33.1a.91.91 0 0 0 .87.91h15.49a.91.91 0 0 0 .88-.91V22.83h-2.1a7.6 7.6 0 0 1 .27 3 7.21 7.21 0 0 1-2.95 5.07 6.61 6.61 0 0 1-7.18.32A6.78 6.78 0 0 1 19 28.44a7.45 7.45 0 0 1-.54-5.61h-2.08zm8.52-3.44a4.67 4.67 0 1 0-4.35-5 1.93 1.93 0 0 0 0 .24 4.59 4.59 0 0 0 4.35 4.76zm8.72-11.18V16.3a.91.91 0 0 0-.89-.93h-2.08a.9.9 0 0 0-.89.91v2.18a1.82 1.82 0 0 0 0 .33.93.93 0 0 0 .87.62h2.08a.91.91 0 0 0 .91-.91z"
        fillRule="evenodd"
      />
    </StyledSvgBase>
  )
}

Instagram.defaultProps = {
  fill: "red",
  title: cuid(),
}
Instagram.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
}

export default Instagram
