import React from 'react'
import cuid from 'cuid'
import PropTypes from 'prop-types'
import StyledSvgBase from './StyledSvgBase'

const Twitter = ({ title, height, fillColor, fillColorHover }) => {
  return (
    <StyledSvgBase
      svgHeight={height}
      data-name={title}
      title={title}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      fillColor={fillColor}
      fillColorHover={fillColorHover}
    >
      <path d="M24.6 19.9zM24.6 20zM24.7 20.5zM24.7 20.5zM24.7 20.5V20v.5zM25 0C11.2 0 0 11.2 0 25s11.2 25 25 25 25-11.2 25-25S38.8 0 25 0zm0 47C12.8 47 3 37.2 3 25S12.8 3 25 3s22 9.8 22 22-9.8 22-22 22z" />
      <path d="M35.2 18c-1.3.5-1.5.4-.9-.3.5-.5.9-1.1 1.1-1.8 0-.1-.2 0-.5.2-.4.2-.9.4-1.4.6l-.9.3-.8-.6c-.4-.3-.9-.5-1.4-.7-.9-.2-1.9-.2-2.8.1-2 .7-3.2 2.7-3.1 4.8v.8l-.8-.1c-2.9-.4-5.6-1.8-7.6-3.9L15 16.3v.8c-.6 1.7-.2 3.6 1 5 .6.7.5.8-.6.4-.4-.1-.7-.2-.8-.2 0 .8.2 1.5.6 2.2.5.9 1.2 1.7 2.2 2.1l.8.4h-.9c-.9 0-.9 0-.8.4.6 1.3 1.6 2.3 3 2.8l1 .4-.9.5c-1.3.8-2.8 1.2-4.3 1.2-.4 0-.9 0-1.3.1.9.6 2 1.1 3.1 1.5 3.5 1.1 7.2.6 10.4-1.2 2.4-1.6 4.2-3.9 5.3-6.5.6-1.6 1-3.3 1.1-5 0-.8 0-.9.9-1.8.4-.4.8-.8 1.1-1.3.2-.4.2-.4-.7-.1zM24.6 19.7c0-.1 0-.1 0 0z" />
    </StyledSvgBase>
  )
}

Twitter.defaultProps = {
  fill: 'red',
  title: cuid()
}
Twitter.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number
}

export default Twitter
