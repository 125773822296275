import styled from "styled-components"

const SocialButton = styled.button`
  background-color: ${props => props.theme.diesel};
  border: 0;
  transition: all 200ms;

  svg {
    &:hover {
      fill: ${props => props.fillColorHover};
      cursor: pointer;
      transform: scale(1.1);
    }
  }
`

export default SocialButton
