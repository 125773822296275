import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { Row, Col } from "react-styled-flexboxgrid"
import { LeadText, SubTitle, Text } from "../../ui/atoms/Typography"
import { Space, StyledGrid } from "../../templates/Layout"
import { Section } from "../../ui/molecules/Section"
import ChopsticksLogo from "../../ui/atoms/ChopsticksLogo"
import { Button } from "../../ui/atoms/CTA"
import IconFacemask from "../../../images/icon-facemask.svg"
import IconHandwrap from "../../../images/icon-handwrap.svg"
import IconTakeout from "../../../images/icon-takeout.svg"

import { calculateRem } from "../../../utils/javascript-styles"

const StyledIcons = styled.img`
  height: ${calculateRem(100)};
`
const About = ({ data }) => {
  return (
    <>
      <Section type="primary" centered>
        <StyledGrid>
          <Row>
            <Col xs={12}>
              <SubTitle type="alternate" align="center">
                Go Touchless!
              </SubTitle>
              <ChopsticksLogo />

              <Text align="center" alternate>
                At last, a unique way to safeguard friends and family from
                germs. Our patented “Touchless” design elevates the chopstick
                tips from germy tables to promote better health. Plus, the
                tapered, ergonomic design was invented by a hand surgeon for an
                easier, more secure grip. Whether you cook at home, order
                takeout or get food delivered, Touchless Chopsticks will help
                keep you and loved ones safe. They’re reusable when you
                hand-wash them with soap and hot water.
              </Text>
              <Button
                onClick={() =>
                  window.open(
                    "https://touchless-tableware.myshopify.com/",
                    "_blank"
                  )
                }
                secondary
              >
                Stock up & Save
              </Button>
            </Col>
          </Row>
        </StyledGrid>
      </Section>
      <Section type="secondary" centered>
        <StyledGrid>
          <Row>
            <Col xs={12}>
              <SubTitle type="alternate" align="center">
                Vital product benefits
              </SubTitle>
              <LeadText type="dark" align="center">
                Promote safety, dexterity and sustainability
              </LeadText>
              <Row>
                <Col xs={12} md={4}>
                  <StyledIcons src={IconFacemask} alt="icon facemask" />
                  <Text align="center" alternate>
                    Helps customers avoid table germs
                  </Text>
                </Col>
                <Col xs={12} md={4}>
                  <StyledIcons src={IconHandwrap} alt="icon handwrap" />
                  <Text align="center" alternate>
                    Puts less pressure on customers hands
                  </Text>
                </Col>
                <Col xs={12} md={4}>
                  <StyledIcons src={IconTakeout} alt="icon takeout" />
                  <Text align="center" alternate>
                    Reusable and Dishwasher safe
                  </Text>
                </Col>
              </Row>
              <Space mb={40} />

              <Button
                onClick={() =>
                  window.open(
                    "https://touchless-tableware.myshopify.com/",
                    "_blank"
                  )
                }
                secondary
              >
                Buy Now
              </Button>
            </Col>
          </Row>
        </StyledGrid>
      </Section>
      {/* <Section type="tertiary" centered>
        <StyledGrid>
          <Row>
            <Col xs={12}>
              <Flower
                src={ImgFlower}
                alt="logo flower design"
                height={150}
                pushUp
              />
              <SubTitle align="center">
                Affordable <span>|</span> Marketable
              </SubTitle>
              <LeadText type="alternate" align="center">
                <italics>
                  "Composting is a natural process that turns organic materials
                  into a conditioner for soil. As an organic-matter resource,
                  compost has the unique ability to improve the chemical,
                  physical and biological characteristics of soils."
                </italics>
              </LeadText>
              <LeadText align="right">
                —BIODEGRADABLE PRODUCTS INSTITUTE
              </LeadText>
            </Col>
          </Row>
        </StyledGrid>
      </Section> */}
      <Section centered>
        <StyledGrid>
          <Row center="md">
            <Col xs={12} md={9}>
              <SubTitle type="alternate" align="center">
                <strong>Mindful and economical</strong>
              </SubTitle>
              <ChopsticksLogo />
              <LeadText type="dark" align="center">
                An ideal solution for food service and health care
              </LeadText>
              <Img
                fluid={data.ImgChopsticksNoodles.childImageSharp.fluid}
                alt="noodles eaten with chopsticks"
              />
              <Space mb={32} />
              <Text alternate align="center">
                Whether you run an eatery, catering service, hospital or
                senior-care facility—Touchless Chopsticks will help prevent the
                spread of germs. Offering them to customers will reduce your
                liability for illness and may reduce your liability claims. They
                also eliminate the need for costly chopsticks stands (or rests)
                and the need to wash them repeatedly. This will save your
                organization resources such as water, labor and time. When
                customers see how proactive you are, your image will improve and
                you’ll be positioned as a health safety leader. Then, the only
                thing that may go viral is the good will you’ll create. All of
                which will help your organization realize even greater success.
                Take charge and fortify your facility today. Go Touchless!
              </Text>
              <Space mb={32} />
              <LeadText type="dark" align="center">
                Fortify your Food Service
              </LeadText>
              <Button
                onClick={() =>
                  window.open(
                    "https://touchless-tableware.myshopify.com/",
                    "_blank"
                  )
                }
                secondary
              >
                Stock Up & Save
              </Button>
            </Col>
          </Row>
        </StyledGrid>
      </Section>
    </>
  )
}

export default About
