import { css } from "styled-components"
import { calculateRem } from "../../../../utils/javascript-styles"

const menuLinkStyle = css`

border-bottom: 2px solid transparent;
color: ${props => props.theme.merlot};
display: block;
display: flex;
flex-direction: column;
font-family: "Poppins", sans-serif;
font-weight: bold;
font-size: ${calculateRem(18)};
justify-content: center;
padding: ${calculateRem(10)};
margin: 0 ${calculateRem(22)};
position: relative;
text-decoration: none;
transition: all 500ms;


svg {
  fill: ${props => props.theme.mexired};
}

&.active {
  color: ${props => props.theme.thunder};
  border-color: ${props => props.theme.thunder};
  svg {
    fill: ${props => props.theme.white};
  }

  &:hover {
    color: ${props => props.theme.merlot};
    border-color: ${props => props.theme.thunder};
    cursor: default;
    transform:translateY(1.1);

    svg {
      fill: ${props => props.theme.white};
    }
  }

  svg {
    fill: ${props => props.theme.white};
  }
  }
}
&:hover {
  color: ${props => props.theme.thunder};
  // border-color: ${props => props.theme.merlot};
  cursor: pointer;

  svg {
    fill: ${props => props.theme.merlot};
  }
}
`
export default menuLinkStyle
