import React from "react"
import styled from "styled-components"
import ImgChopsticksLogo from "../../../../images/icon-chopsticks.svg"
import { calculateRem } from "../../../../utils/javascript-styles"

const StyledChopsticksLogo = styled.img`
  width: ${calculateRem(200)};
  margin: 0 auto ${calculateRem(32)} auto;
  display: block;
`

const ChopsticksLogo = () => (
  <StyledChopsticksLogo
    src={ImgChopsticksLogo}
    alt="illustration of chopsticks logo"
  />
)

export default ChopsticksLogo
