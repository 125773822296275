import React, { useState, useEffect, useCallback } from "react"
import MediaQuery from "react-responsive"
import cuid from "cuid"

import DesktopNavigation from "./DesktopNavigation"
import MobileNavigation from "./MobileNavigation"

const Navigation = ({
  yAxisAbout,
  yAxisGallery,
  yAxisBlog,
  yAxisPress,
  yAxisContact,
}) => {
  const navMenu = [
    {
      id: cuid(),
      route: "/",
      title: "Home",
      anchor: "home",
      isActive: false,
    },
    {
      id: cuid(),
      route: "about",
      title: "About",
      anchor: "about",
      isActive: false,
      subNav: false,
    },
    // {
    //   id: cuid(),
    //   route: "",
    //   title: "Lab",
    //   anchor: "lab",
    //   isActive: false,
    //   subNav: false,
    // },

    {
      id: cuid(),
      route: "gallery",
      title: "Gallery",
      anchor: "gallery",
      isActive: false,
      subNav: false,
    },
    {
      id: cuid(),
      route: "blog",
      title: "Blog",
      anchor: "blog",
      isActive: false,
      subNav: false,
    },
    {
      id: cuid(),
      route: "press",
      title: "Press",
      anchor: "press",
      isActive: false,
      subNav: false,
    },
    {
      id: cuid(),
      route: "contact",
      title: "Contact",
      anchor: "contact",
      isActive: false,
      subNav: false,
    },
  ]

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [yPos, setYPos] = useState(null)
  const [activeLink, setActiveLink] = useState("home")

  const openMobileMenu = () => {
    setMobileMenuOpen(prevState => {
      return !prevState
    })
  }

  // function controlAfterAnimate(anchor) {
  //   setActiveLink(anchor)
  //   setMobileMenuOpen(false)
  // }

  const mobileAfterAnimate = () => {
    setMobileMenuOpen(false)
  }
  const handleScroll = useCallback(() => {
    const yAxis = window.pageYOffset
    setYPos(yAxis)
  }, [])

  useEffect(() => {
    // Update the document title using the browser API
    window.addEventListener("scroll", handleScroll)
    return () => {
      if (typeof window !== `undefined`) {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  }, [handleScroll])

  useEffect(() => {
    if (yPos < yAxisAbout) {
      setActiveLink("home")
    }
    if (yPos > yAxisAbout - 80 && yPos < yAxisGallery - 80) {
      setActiveLink("about")
    }
    if (yPos > yAxisGallery - 80 && yPos < yAxisBlog - 80) {
      setActiveLink("gallery")
    }
    if (yPos > yAxisBlog - 80 && yPos < yAxisPress - 80) {
      setActiveLink("blog")
    }
    if (yPos > yAxisPress - 80 && yPos < yAxisContact - 80) {
      setActiveLink("press")
    }
    if (yPos >= yAxisContact - 80) {
      setActiveLink("contact")
    }
  }, [yPos, yAxisAbout, yAxisGallery, yAxisBlog, yAxisPress, yAxisContact])

  return (
    <section>
      <MediaQuery query="(max-device-width: 1224px)">
        <MobileNavigation
          activeLink={activeLink}
          mobileMenuControlFunc={openMobileMenu}
          mobileMenuOpen={mobileMenuOpen}
          navMenu={navMenu}
          activeMenuControlFunc={setActiveLink}
          controlAfterAnimate={mobileAfterAnimate}
        />
      </MediaQuery>
      <MediaQuery query="(min-device-width: 1225px)">
        <DesktopNavigation activeLink={activeLink} navMenu={navMenu} />
      </MediaQuery>
    </section>
  )
}

export default Navigation
