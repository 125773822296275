import React from "react"
import { Grid, Row, Col } from "react-styled-flexboxgrid"
import styled from "styled-components"
import Flex from "../../atoms/Flex"
import Social from "../../organisms/Social"
import { Text } from "../../atoms/Typography"
import ImgFlower from "../../../../images/chopsticks-flower.svg"

import { calculateRem } from "../../../../utils/javascript-styles"

const StyledFooter = styled.footer`
  background-color: ${props => props.theme.diesel};
  color: ${props => props.theme.canonPink};
  padding: ${calculateRem(32)};
`
const Flower = styled.img`
  height: ${calculateRem(150)};
`

const Footer = () => {
  return (
    <StyledFooter>
      <Grid>
        <Row>
          <Col xs={12} md={4}>
            <Text>
              © 2019 - {new Date().getFullYear()}. Michael Joyner, MD. All
              Rights Reserved
            </Text>
          </Col>
          <Col xs={12} md={4}>
            <Flex justifyContent="center">
              <Flower src={ImgFlower} alt="logo flower design" />
            </Flex>
          </Col>
          <Col xs={12} md={4}>
            <Social />
          </Col>
        </Row>
      </Grid>
    </StyledFooter>
  )
}

export default Footer
