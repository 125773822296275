import React from 'react';
import styled from 'styled-components';

const RadioWrapper = styled.div`
  postion: relative;
  input[type='radio'] {
    opacity: 0;
    display: none;
    visibility: hidden;
    position: absolute;
    left: -9999px;
  }
  label::before {
    content: '';
    display: inline-block;

    height: 16px;
    width: 16px;

    border: 1px solid;
  }
  label::after {
    content: '';
    display: inline-block;
    height: 6px;
    width: 9px;
    border-left: 2px solid;
    border-bottom: 2px solid;

    transform: rotate(-45deg);
  }
`;

const Radio = () => {
  return (
    <RadioWrapper>
      <input type="radio" id="test" />
      <label htmlFor="test">Test</label>
    </RadioWrapper>
  );
};

export default Radio;
