import React from "react"
import cuid from "cuid"
import PropTypes from "prop-types"
import StyledSvgBase from "./StyledSvgBase"

const IconMobileMenu = ({ title, height, fillColor, fillColorHover }) => {
  return (
    <StyledSvgBase
      svgHeight={height}
      data-name={title}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      fillColor={fillColor}
      fillColorHover={fillColorHover}
      role="img"
    >
      <title>{title}</title>
      <path d="M0 0h48v48H0z" fill="none" />
      <path d="M6 36h36v-4H6v4zm0-10h36v-4H6v4zm0-14v4h36v-4H6z" />
    </StyledSvgBase>
  )
}

IconMobileMenu.defaultProps = {
  bgColor: "transparent",
  fill: "red",
  title: cuid(),
}
IconMobileMenu.propTypes = {
  bgColor: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
}

export default IconMobileMenu
