import React from "react"
import cuid from "cuid"
import Carousel from "react-images"
import PhotoGallery from "react-photo-gallery"
import { Row, Col } from "react-styled-flexboxgrid"
import { Space } from "../../templates/Layout"
import { SubTitle } from "../../ui/atoms/Typography"
import { StyledGrid } from "../../templates/Layout"
import { Section } from "../../ui/molecules/Section"

import ImgChop01 from "../../../images/gallery-chopsticks/01.jpg"
import ImgChop02 from "../../../images/gallery-chopsticks/02.jpg"
import ImgChop03 from "../../../images/gallery-chopsticks/03.jpg"
import ImgChop04 from "../../../images/gallery-chopsticks/04.jpg"

import ImgMonthTwo3 from "../../../images/gallery-chopsticks/touchless-month-002/Touchless_MonthTwo-3.jpg"
import ImgMonthTwo4 from "../../../images/gallery-chopsticks/touchless-month-002/Touchless_MonthTwo-4.jpg"
import ImgMonthTwo6 from "../../../images/gallery-chopsticks/touchless-month-002/Touchless_MonthTwo-6.jpg"
import ImgMonthTwo8 from "../../../images/gallery-chopsticks/touchless-month-002/Touchless_MonthTwo-8.jpg"
// import ImgMonthTwo9 from "../../../images/gallery-chopsticks/touchless-month-002/Touchless_MonthTwo-9.jpg"
// import ImgMonthTwo11 from "../../../images/gallery-chopsticks/touchless-month-002/Touchless_MonthTwo-11.jpg"
// import ImgMonthTwo12 from "../../../images/gallery-chopsticks/touchless-month-002/Touchless_MonthTwo-12.jpg"
// import ImgMonthTwo14 from "../../../images/gallery-chopsticks/touchless-month-002/Touchless_MonthTwo-14.jpg"
// import ImgMonthTwo15 from "../../../images/gallery-chopsticks/touchless-month-002/Touchless_MonthTwo-15.jpg"
import ImgMonthTwo17 from "../../../images/gallery-chopsticks/touchless-month-002/Touchless_MonthTwo-17.jpg"
// import ImgMonthTwo19 from "../../../images/gallery-chopsticks/touchless-month-002/Touchless_MonthTwo-19.jpg"
// import ImgMonthTwo21 from "../../../images/gallery-chopsticks/touchless-month-002/Touchless_MonthTwo-21.jpg"
import ImgMonthTwo26 from "../../../images/gallery-chopsticks/touchless-month-002/Touchless_MonthTwo-26.jpg"
import ImgMonthTwo29 from "../../../images/gallery-chopsticks/touchless-month-002/Touchless_MonthTwo-29.jpg"
import ImgMonthTwo31 from "../../../images/gallery-chopsticks/touchless-month-002/Touchless_MonthTwo-31.jpg"
import ImgMonthTwo33 from "../../../images/gallery-chopsticks/touchless-month-002/Touchless_MonthTwo-33.jpg"
import ImgMonthTwo34 from "../../../images/gallery-chopsticks/touchless-month-002/Touchless_MonthTwo-34.jpg"
import ImgMonthTwo36 from "../../../images/gallery-chopsticks/touchless-month-002/Touchless_MonthTwo-36.jpg"
import ImgMonthTwo38 from "../../../images/gallery-chopsticks/touchless-month-002/Touchless_MonthTwo-38.jpg"
import ImgMonthTwo41 from "../../../images/gallery-chopsticks/touchless-month-002/Touchless_MonthTwo-41.jpg"

const photosChopsticks = [
  {
    id: cuid(),
    source: ImgChop01,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "image 1",
  },
  {
    id: cuid(),
    source: ImgChop02,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "image 2",
  },
  {
    id: cuid(),
    source: ImgChop03,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "image 3",
  },
  {
    id: cuid(),
    source: ImgChop04,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "image 4",
  },
]

const photosMonthTwoA = [
  {
    id: cuid(),
    src: ImgMonthTwo3,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 10,
    height: 7,
    alt: "touchless flatware set",
  },
  {
    id: cuid(),
    src: ImgMonthTwo4,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 10,
    height: 7,
    alt: "touchless flatware set",
  },
  {
    id: cuid(),
    src: ImgMonthTwo6,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 10,
    height: 7,
    alt: "touchless flatware set",
  },
  {
    id: cuid(),
    src: ImgMonthTwo8,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 10,
    height: 7,
    alt: "touchless flatware set",
  },
  // {
  //   id: cuid(),
  //   src: ImgMonthTwo9,
  //   sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
  //   width: 10,
  //   height: 7,
  //   alt: "touchless flatware set",
  // },
  // {
  //   id: cuid(),
  //   src: ImgMonthTwo11,
  //   sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
  //   width: 10,
  //   height: 7,
  //   alt: "touchless flatware set",
  // },
  // {
  //   id: cuid(),
  //   src: ImgMonthTwo12,
  //   sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
  //   width: 10,
  //   height: 7,
  //   alt: "touchless flatware set",
  // },
  // {
  //   id: cuid(),
  //   src: ImgMonthTwo14,
  //   sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
  //   width: 10,
  //   height: 7,
  //   alt: "touchless flatware set",
  // },
  // {
  //   id: cuid(),
  //   src: ImgMonthTwo15,
  //   sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
  //   width: 10,
  //   height: 7,
  //   alt: "touchless flatware set",
  // },
  {
    id: cuid(),
    src: ImgMonthTwo17,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 10,
    height: 7,
    alt: "touchless flatware set",
  },
]
const photosMonthTwoB = [
  // {
  //   id: cuid(),
  //   src: ImgMonthTwo19,
  //   sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
  //   width: 10,
  //   height: 7,
  //   alt: "touchless flatware set",
  // },
  // {
  //   id: cuid(),
  //   src: ImgMonthTwo21,
  //   sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
  //   width: 10,
  //   height: 7,
  //   alt: "touchless flatware set",
  // },
  {
    id: cuid(),
    src: ImgMonthTwo26,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 10,
    height: 7,
    alt: "touchless flatware set",
  },
  {
    id: cuid(),
    src: ImgMonthTwo29,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 10,
    height: 7,
    alt: "touchless flatware set",
  },
  {
    id: cuid(),
    src: ImgMonthTwo31,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 10,
    height: 7,
    alt: "touchless flatware set",
  },
  {
    id: cuid(),
    src: ImgMonthTwo33,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 10,
    height: 7,
    alt: "touchless flatware set",
  },
  {
    id: cuid(),
    src: ImgMonthTwo34,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 10,
    height: 7,
    alt: "touchless flatware set",
  },
  {
    id: cuid(),
    src: ImgMonthTwo36,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 10,
    height: 7,
    alt: "touchless flatware set",
  },
  {
    id: cuid(),
    src: ImgMonthTwo38,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 10,
    height: 7,
    alt: "touchless flatware set",
  },
  {
    id: cuid(),
    src: ImgMonthTwo41,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 10,
    height: 7,
    alt: "touchless flatware set",
  },
]

const Gallery = () => {
  return (
    <Section type="secondary" centered>
      <StyledGrid>
        <Row center="md">
          <Col xs={12}>
            <SubTitle type="alternate" align="center">
              <strong>Photo Gallery</strong>
            </SubTitle>
            <Carousel views={photosChopsticks} />
            <Space mb={32} />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <PhotoGallery photos={photosMonthTwoA} />

            <PhotoGallery photos={photosMonthTwoB} />
            <Space mb={32} />
          </Col>
        </Row>
      </StyledGrid>
    </Section>
  )
}

export default Gallery
