import React from "react"
import styled from "styled-components"
import { calculateRem } from "../../../../utils/javascript-styles"

const StyledError = styled.div`
  position: relative;
  background: ${props => props.theme.error};
  padding: ${calculateRem(16)};
  text-align: left;
  color: #fff;
  &:after {
    bottom: 100%;
    left: 32px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 110, 110, 0);
    border-bottom-color: ${props => props.theme.error};
    border-width: 10px;
    margin-left: -10px;
  }
`

const ErrorMessage = ({ children }) => {
  return <StyledError>{children}</StyledError>
}

export default ErrorMessage
