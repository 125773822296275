import React, { useState, useRef, useLayoutEffect } from "react"
import { Row, Col } from "react-styled-flexboxgrid"
import { Layout } from "../Layout"
import Navigation from "../../ui/organisms/Navigation"
import Footer from "../../ui/organisms/Footer"
import Home from "../../pages/Home"
import About from "../../pages/About"
import Press from "../../pages/Press"
import Blog from "../../pages/Blog"
import Contact from "../../pages/Contact"
import Gallery from "../../pages/Gallery"

const ScrollContainer = ({ data }) => {
  // console.log("scrollcont: ", data)
  const refHome = useRef()
  const refAbout = useRef()
  const refGallery = useRef()
  const refBlog = useRef()
  const refPress = useRef()
  const refContact = useRef()

  const [posAbout, setPosAbout] = useState(null)
  const [posGallery, setPosGallery] = useState(null)
  const [posBlog, setPosBlog] = useState(null)
  const [posPress, setPosPress] = useState(null)
  const [posContact, setPosContact] = useState(null)

  useLayoutEffect(() => {
    function updatePosition() {
      setPosAbout(refAbout.current.offsetTop)
      setPosGallery(refGallery.current.offsetTop)
      setPosBlog(refBlog.current.offsetTop)
      setPosPress(refPress.current.offsetTop)
      setPosContact(refContact.current.offsetTop)
    }
    window.addEventListener("resize", updatePosition)
    updatePosition()
    return () => window.removeEventListener("resize", updatePosition)
  }, [])
  return (
    <Layout>
      <Row>
        <Col xs={12}>
          <Navigation
            yAxisAbout={posAbout}
            yAxisGallery={posGallery}
            yAxisBlog={posBlog}
            yAxisPress={posPress}
            yAxisContact={posContact}
          />
          <section id="home" ref={refHome}>
            <Home data={data} />
          </section>
          <section id="about" ref={refAbout}>
            <About data={data} />
          </section>
          <section id="gallery" ref={refGallery}>
            <Gallery />
          </section>
          <section id="blog" ref={refBlog}>
            <Blog data={data} />
          </section>
          <section id="press" ref={refPress}>
            <Press data={data} />
          </section>
          <section id="contact" ref={refContact}>
            <Contact />
          </section>

          <Footer />
        </Col>
      </Row>
    </Layout>
  )
}

ScrollContainer.propTypes = {}

export default ScrollContainer
