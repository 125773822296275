import React from "react"
import cuid from "cuid"
import PropTypes from "prop-types"
import StyledSvgBase from "./StyledSvgBase"

const IconMobileMenuClose = ({ title, height, fillColor, fillColorHover }) => {
  return (
    <StyledSvgBase
      svgHeight={height}
      data-name={title}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      fillColor={fillColor}
      fillColorHover={fillColorHover}
      role="img"
    >
      <title>{title}</title>
      <path d="M38 12.83L35.17 10 24 21.17 12.83 10 10 12.83 21.17 24 10 35.17 12.83 38 24 26.83 35.17 38 38 35.17 26.83 24z" />
      <path d="M0 0h48v48H0z" fill="none" />
    </StyledSvgBase>
  )
}

IconMobileMenuClose.defaultProps = {
  fill: "red",
  title: cuid(),
}
IconMobileMenuClose.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
}

export default IconMobileMenuClose
