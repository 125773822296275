import React from "react"
import cuid from "cuid"
import PropTypes from "prop-types"
import StyledSvgBase from "./StyledSvgBase"

const House = ({ title, height, fillColor, fillColorHover }) => {
  return (
    <StyledSvgBase
      svgHeight={height}
      data-name={title}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      fillColor={fillColor}
      fillColorHover={fillColorHover}
      role="img"
    >
      <svg
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 36.02 35.16"
      >
        <g data-name="Group 2">
          <path
            data-name="Path 23"
            d="M5.25 17.44l-.19.16-2.37 2.31c-.15.15-.24.16-.39 0-.71-.74-1.43-1.47-2.15-2.21-.2-.2-.2-.2 0-.41l13.88-13.5L17.81.11c.14-.14.22-.15.37 0q8.55 8.33 17.1 16.64c.21.21.43.43.65.63s.1.16 0 .25l-2.28 2.35c-.13.13-.2.06-.3 0L31 17.58l-.25-.21v12.26a5.28 5.28 0 01-2.68 4.73 5 5 0 01-2.64.75h-5.32c-.22 0-.25-.1-.25-.28v-8.45c0-.33 0-.33-.34-.33h-3c-.32 0-.32 0-.32.31v8.42c0 .33 0 .33-.33.33-1.82 0-3.63.06-5.45 0a5.42 5.42 0 01-5.12-5.48V17.47zm3.68 4.42V29.64a1.82 1.82 0 001.75 1.91h1.57c.31 0 .31 0 .31-.31v-8.49c0-.28 0-.28.27-.28h10.36c.2 0 .27 0 .27.25v8.66c0 .13 0 .18.17.18h1.7a1.78 1.78 0 001.72-1.52 5.92 5.92 0 000-1V14.19a.6.6 0 00-.2-.47l-8.7-8.46c-.16-.16-.25-.14-.4 0l-8.68 8.45a.62.62 0 00-.2.48v7.74z"
          />
        </g>
      </svg>
    </StyledSvgBase>
  )
}

House.defaultProps = {
  fill: "red",
  title: cuid(),
}
House.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
}

export default House
