import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { calculateRem } from "../../../../utils/javascript-styles"

const CardWrapper = styled.div`
  background-color: white;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
  margin: ${calculateRem(16)};
  padding: ${calculateRem(32)};
  border: 1px solid ${props => props.theme.mineshaft};
`

const Card = ({ key, children }) => {
  return <CardWrapper key={key}>{children}</CardWrapper>
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Card
