import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const StyledFlex = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection};
  justify-content: ${props => props.justifyContent};
  flex-wrap: wrap;
  height: ${props => props.height};
`

const Flex = ({ justifyContent, children, height, flexDirection }) => {
  return (
    <StyledFlex
      justifyContent={justifyContent}
      height={height}
      flexDirection={flexDirection}
    >
      {children}
    </StyledFlex>
  )
}

Flex.defaultProps = {
  justifyContent: "flex-start",
  flexDirection: "row",
  height: "default",
}
Flex.propTypes = {
  height: PropTypes.string,
  justifyContent: PropTypes.oneOf([
    "flex-start",
    "flex-end",
    "center",
    "space-between",
    "space-around",
    "space-evenly",
  ]),
  flexDirection: PropTypes.oneOf([
    "row",
    "row-reverse",
    "column",
    "column-reverse",
  ]),
  children: PropTypes.node,
}

export default Flex
