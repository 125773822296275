import React from "react"
import qs from "qs"
import axios from "axios"
import { navigate } from "gatsby"
import { useForm } from "react-hook-form"
import { Row, Col } from "react-styled-flexboxgrid"
import ChopsticksLogo from "../../ui/atoms/ChopsticksLogo"
import { LeadText, SubTitle, Text } from "../../ui/atoms/Typography"
import { StyledGrid, Space } from "../../templates/Layout"
import { Section } from "../../ui/molecules/Section"
import { Button } from "../../ui/atoms/CTA"
import { Input, Label, TextArea, ErrorMessage } from "../../ui/atoms/Form"

const Contact = () => {
  const { register, handleSubmit, errors } = useForm()

  const onSubmit = (data, e) => {
    e.preventDefault()
    const form = e.target
    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: qs.stringify({ "form-name": "contact", ...data }),
      url: "/thanks",
    }
    axios(options)
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  return (
    <>
      <Section type="primary" centered>
        <StyledGrid>
          <Row>
            <Col xs={12}>
              <SubTitle type="alternate" align="center">
                Get In Touch
              </SubTitle>
              <ChopsticksLogo />
              <LeadText type="dark" align="center">
                Change the world now with Touchless Chopsticks
              </LeadText>

              <Text align="center">
                To learn more or submit a special request:
              </Text>
              <form
                name="contact"
                method="POST"
                action="/thanks"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit(onSubmit)}
              >
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact" />
                <Label htmlFor="name">Your Name:* </Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  defaultValue=""
                  ref={register({ required: true })}
                />
                {errors.name && (
                  <ErrorMessage>Please enter your name</ErrorMessage>
                )}
                <Label htmlFor="email">Your Email:* </Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  defaultValue=""
                  ref={register({ required: true })}
                />
                {errors.email && (
                  <ErrorMessage>Please enter your email address</ErrorMessage>
                )}
                <Label htmlFor="business">Your Business: </Label>
                <Input
                  type="business"
                  name="business"
                  id="business"
                  defaultValue=""
                  ref={register}
                />

                <Space mb={40} />
                <Label htmlFor="message">Message:* </Label>
                <TextArea
                  name="message"
                  id="message"
                  defaultValue=""
                  ref={register({ required: true })}
                />
                {errors.message && (
                  <ErrorMessage>Please enter your message</ErrorMessage>
                )}
                <Button secondary type="submit">
                  Send Your Message
                </Button>
              </form>
            </Col>
          </Row>
        </StyledGrid>
      </Section>
    </>
  )
}

export default Contact
