import styled from "styled-components"
import { calculateRem } from "../../../../utils/javascript-styles"

const Input = styled.input`
  width: 100%;
  height: ${calculateRem(50)};
  border: 1px solid ${props => props.theme.thunder};
  background-color: ${props => props.theme.jacarta};
  margin-bottom: 1rem;
  color: ${props => props.theme.merlot};
  padding: 1rem;
`

export default Input
