import React from 'react';
import cuid from 'cuid';
import PropTypes from 'prop-types';
import StyledSvgBase from './StyledSvgBase';

const Facebook = ({ title, height, fillColor, fillColorHover }) => {
  return (
    <StyledSvgBase
      svgHeight={height}
      data-name={title}
      title={title}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      fillColor={fillColor}
      fillColorHover={fillColorHover}
    >
      <path d="M26.82 19.31c0-1 .1-1.52 1.62-1.52h2V14h-3.22c-3.91 0-5.28 1.84-5.28 4.93v2.28H19.5V25h2.44v11h4.87V25h3.26l.43-3.79h-3.69z" />
      <path d="M25 0a25 25 0 1 0 25 25A25 25 0 0 0 25 0zm0 47a22 22 0 1 1 22-22 22 22 0 0 1-22 22z" />
    </StyledSvgBase>
  );
};

Facebook.defaultProps = {
  fill: 'red',
  title: cuid()
};
Facebook.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number
};

export default Facebook;
