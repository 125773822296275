import React from "react"
import PropTypes from "prop-types"
import {
  LinkedIn,
  Instagram,
  Facebook,
  SocialButton,
} from "../../molecules/Icons"
import Flex from "../../atoms/Flex"
import { navigateTo } from "../../../../utils/utils"

const Social = () => {
  return (
    <Flex justifyContent="flex-end">
      <SocialButton
        onClick={() =>
          navigateTo(
            "https://www.linkedin.com/company/touchless-flatware-chopsticks-corporation/"
          )
        }
        type="button"
      >
        <LinkedIn
          title="linkedin svg"
          height={30}
          fillColor="#A64553"
          fillColorHover="#FFF"
        />
      </SocialButton>

      <SocialButton
        onClick={() => navigateTo("https://www.facebook.com/GoTouchless")}
        type="button"
      >
        {/* <img src={SocialFacebook} alt="social facebook" /> */}
        <Facebook
          title="facebook svg"
          height={30}
          fillColor="#A64553"
          fillColorHover="#FFF"
        />
      </SocialButton>

      <SocialButton
        onClick={() => navigateTo("https://www.instagram.com/docctor_j/")}
        type="button"
      >
        {/* <img src={SocialFacebook} alt="social facebook" /> */}
        <Instagram
          title="instagram svg"
          height={30}
          fillColor="#A64553"
          fillColorHover="#FFF"
        />
      </SocialButton>
    </Flex>
  )
}

Social.defaultProps = {
  justifyContent: "flex-start",
}

Social.propTypes = {
  justifyContent: PropTypes.oneOf([
    "flex-start",
    "flex-end",
    "center",
    "space-between",
    "space-around",
    "space-evenly",
  ]),
}

export default Social
