import React from "react"
import PropTypes from "prop-types"
import StyledSvgBase from "./StyledSvgBase"

const LinkedIn = ({ title, height, fillColor, fillColorHover }) => {
  return (
    <StyledSvgBase
      svgHeight={height}
      data-name={title}
      title={title}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      fillColor={fillColor}
      fillColorHover={fillColorHover}
    >
      <path d="M16.66 13a2.51 2.51 0 1 0-.5 5 2.14 2.14 0 0 0 .44 0 2.51 2.51 0 0 0 .45-5h-.39zM14 20.12h4.71v14.54H14z" />
      <path d="M25 0a25 25 0 1 0 25 25A25 25 0 0 0 25 0zm0 47a22 22 0 1 1 22-22 22 22 0 0 1-22 22z" />
      <path d="M30.58 20.12a4.66 4.66 0 0 0-4.25 2.4v-2.06h-4.71c.06 1.36 0 14.54 0 14.54h4.71v-8.12a3.37 3.37 0 0 1 .16-1.18 2.58 2.58 0 0 1 2.41-1.77c1.71 0 2.39 1.34 2.39 3.29V35H36v-8.34c0-4.46-2.32-6.54-5.42-6.54z" />
    </StyledSvgBase>
  )
}

LinkedIn.defaultProps = {
  height: 50,
  width: 50,
  fill: "red",
}
LinkedIn.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
}

export default LinkedIn
