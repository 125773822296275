import React from "react"
import { graphql } from "gatsby"
import ScrollContainer from "../components/templates/ScrollContainer"

const IndexPage = ({ data }) => <ScrollContainer data={data} />

export default IndexPage

export const pageQuery = graphql`
  query {
    allWordpressPost {
      edges {
        node {
          title
          content
          slug
        }
      }
    }
    imgchopsticks: file(relativePath: { eq: "chopsticks-white-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ImgChopsticksNoodles: file(relativePath: { eq: "chopsticks-noodles.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ImgBabyTurtle: file(relativePath: { eq: "baby-turtle.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ImgFirstResponder: file(relativePath: { eq: "first-responder.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ImgGoldChopsticks: file(relativePath: { eq: "golden-chopsticks.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
