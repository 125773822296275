import React from "react"
import Scrollchor from "react-scrollchor"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Grid, Row, Col } from "react-styled-flexboxgrid"
import MenuLinkStyle from "./style"
import { calculateRem } from "../../../../utils/javascript-styles"

import Logo from "../../../../images/logo-typestack.svg"

const navArray = PropTypes.shape({
  id: PropTypes.string,
  route: PropTypes.string,
  customClass: PropTypes.string,
  title: PropTypes.string,
})

const propTypes = {
  navMenu: PropTypes.arrayOf(navArray).isRequired,
  controlAfterAnimate: PropTypes.func,
  activeLink: PropTypes.string.isRequired,
}

const StyledNav = styled.nav`
  padding: ${calculateRem(22)};
  background-color: white;
  position: fixed;
  z-index: 100;
  width: 100%;
  box-shadow: 1px 1px 11px rgba(0, 0, 0, 0.1);
`

const Menu = styled.div`
  display: flex;
  justify-content: flex-end;
`

const MenuItemHome = styled(Scrollchor)`
  margin-bottom: 0;
  padding: 0 ${calculateRem(22)};
  styledlink-decoration: none;
  transition: background-color 0.25s;

  &:hover {
    cursor: pointer;
  }
`

const MenuItemLogo = styled.img`
  margin-bottom: 0;
  height: ${calculateRem(45)};
`

const MenuItem = styled(Scrollchor)`
  ${MenuLinkStyle};
`
const MenuItemShop = styled.button`
  ${MenuLinkStyle};
  border: 0;
  background-color: transparent;
  &:hover {
    cursor: pointer;
  }
`

const DesktopNavigation = ({ navMenu, controlAfterAnimate, activeLink }) => {
  return (
    <StyledNav>
      <Grid>
        <Row>
          <Col xs={12} md={6}>
            <MenuItemHome to="/" activeClassName="active">
              <MenuItemLogo src={Logo} alt="logo" />
            </MenuItemHome>
          </Col>
          <Col xs={12} md={6}>
            <Menu>
              {navMenu.map(item => (
                <MenuItem
                  key={item.anchor}
                  to={item.anchor}
                  // beforeAnimate={() => controlAfterAnimate(item.anchor)}
                  className={activeLink === item.anchor ? "active" : ""}
                >
                  {item.title}
                </MenuItem>
              ))}
              <MenuItemShop
                onClick={() =>
                  window.open(
                    "https://touchless-tableware.myshopify.com/",
                    "_blank"
                  )
                }
                secondary
              >
                Shop
              </MenuItemShop>
            </Menu>
          </Col>
        </Row>
      </Grid>
    </StyledNav>
  )
}
DesktopNavigation.propTypes = propTypes

export default DesktopNavigation
