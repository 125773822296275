import React from "react"
import { Link } from "gatsby"
import { Row, Col } from "react-styled-flexboxgrid"
import { Card } from "../../ui/molecules/Card"
import ChopsticksLogo from "../../ui/atoms/ChopsticksLogo"
import { LeadText, SubTitle } from "../../ui/atoms/Typography"
import { StyledGrid, Space } from "../../templates/Layout"
import { Section } from "../../ui/molecules/Section"

const Blog = ({ data }) => {
  return (
    <>
      <Section type="primary" centered>
        <StyledGrid>
          <Row center="md">
            <Col xs={12} md={9}>
              <SubTitle type="alternate" align="center">
                <strong>Articles on Touchless</strong>
              </SubTitle>
              <ChopsticksLogo />
              <Row>
                {data.allWordpressPost.edges.map((post, index) => {
                  const { title, slug } = post.node
                  return (
                    <Col xs={12} key={index}>
                      <Card>
                        <LeadText type="dark" align="center">
                          {title.toString()}
                        </LeadText>

                        <Link align="center" to={slug}>
                          Read more..
                        </Link>
                        <Space mb={32} />
                      </Card>
                    </Col>
                  )
                })}
              </Row>
              <Space mb={32} />
              <Row center="xs">
                <Col xs={12} md={9}>
                  <SubTitle type="alternate" align="center">
                    <strong>Blog Release</strong>
                  </SubTitle>
                </Col>
              </Row>
            </Col>
          </Row>
        </StyledGrid>
      </Section>
    </>
  )
}

export default Blog
