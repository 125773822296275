import styled from "styled-components"

const Label = styled.label`
  text-align: left;
  display: block;
  color: ${props => props.theme.thunder};
  margin-top: 2rem;
`

export default Label
